import React from 'react';
import Card from 'react-bootstrap/Card';
import { TbWorld } from 'react-icons/tb'
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { verticalsMap } from '../utilities/constants';

function Company(props) {
  // Variables
  const name = props.name;
  const data = props.data;
  const description = data.description;
  const isWebsite = data.website != "";
  const cohort = data.cohort;
  const verticals = data.verticals;
  var tags = [];

  // Prepping Tags
  tags.push(
    <Badge pill key={cohort} style={{backgroundColor: "white", color: "black"}} bg="">
      {cohort}
    </Badge>
  );
  var temp = [];
  temp = verticals.map((tag) => 
    <Badge pill key={tag} style={{backgroundColor: verticalsMap.get(tag), color: "black"}} bg="">
        {tag}
    </Badge>
  )
  tags.push(temp);

  // Render Function
  return (
    <div className="companies-card-container" id={data.logo}>
      <Card>
        <Card.Body>
          <Card.Img src={require(`../assets/images/companylogosblue/${data.logo}.png`)}></Card.Img>
          <div className="companies-card-information">
            <div className="companies-text-container">
              <Card.Title>{name}</Card.Title>
              <Card.Subtitle>{description}</Card.Subtitle>
            </div>
            <div className="companies-icons">
              {isWebsite && <a href={data.website} target="_blank" rel="noopener noreferrer">
                <TbWorld size={23} color={"white"}/>
              </a>}
            </div>
          </div>
          <Stack direction="horizontal">{tags}</Stack>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Company;