import React, {useState} from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { jobLink } from "../utilities/constants";

function Engage() {
  // Media Query
  const isLargerThanSplit = useMediaQuery({ query: '(min-width: 875px)'});

  // State Hooks (Animation Permanence)
  const [applyBackgroundAnimationState, setApplyBackgroundAnimationState] = useState(false)
  const [applyButtonAnimationState, setApplyButtonAnimationState] = useState(false)
  const [joinUsCardAnimationState, setJoinUsCardAnimationState] = useState(false)
  const [joinUsButtonAnimationState, setJoinUsButtonAnimationState] = useState(false)

  // Framer Variants
  const applyBackgroundVariants = {
    offscreen: () => {
      if (applyBackgroundAnimationState) {
        return {
          backgroundPosition: "left"
        }
      } else {
        return {
          backgroundPosition: "right"
        }
      }
    },
    onscreen: () => {
      setApplyBackgroundAnimationState(true)
      return {
        backgroundPosition: "left"
      }
    }
  }
  const applyButtonVariants = {
    offscreen: () => {
      if (applyButtonAnimationState) {
        return {
          backgroundPosition: "left"
        }
      } else {
        return {
          backgroundPosition: "right"
        }
      }
    },
    onscreen: () => {
      setApplyButtonAnimationState(true)
      return {
        backgroundPosition: "left"
      }
    }
  }
  const joinUsCardVariants = {
    offscreen: () => {
      if (joinUsCardAnimationState) {
        return {
          backgroundPosition: "bottom -5px left",
          color: "rgba(0, 0, 0, 0.68)"
        }
      } else {
        return {
          backgroundPosition: "top right",
          color: "white"
        }
      }
    },
    onscreen: () => {
      setJoinUsCardAnimationState(true)
      return {
        backgroundPosition: "bottom -5px left",
        color: "rgba(0, 0, 0, 0.68)"
      }
    }
  }
  const joinUsButtonVariants = {
    offscreen: () => {
      if (joinUsButtonAnimationState) {
        return {
          backgroundPosition: "left -5px",
          color: "white"
        }
      } else {
        return {
          backgroundPosition: "right",
          color: "black"
        }
      }
    },
    onscreen: () => {
      setJoinUsButtonAnimationState(true)
      return {
        backgroundPosition: "left -5px",
        color: "white"
      }
    }
  }
    
  // Render Method
  return (
    <div>
      {isLargerThanSplit && 
        <motion.div className="engage" initial="offscreen" whileInView="onscreen" viewport={{ once:true, amount:0.5}}>
          <div className="engage-cap">
            <motion.div className="apply-background" variants={applyBackgroundVariants}>
              <Card className="apply">
                <Card.Body>
                  {/* <Card.Title>Our Companies</Card.Title> */}
                  <Card.Text>Learn more about our<br/>previous cohorts.</Card.Text>
                  <div className="apply-button-container">
                    <Link to="/companies"><motion.button className="white-button">OUR COMPANIES</motion.button></Link>
                    <motion.div className="apply-button-background" variants={applyButtonVariants}></motion.div>
                  </div>
                </Card.Body>
              </Card>
            </motion.div>
            <motion.div className="join-us card" variants={joinUsCardVariants}>
              <Card.Body>
                {/* <Card.Title>Join Us</Card.Title> */}
                <Card.Text>Join the Wharton Cypher Accelerator community.</Card.Text>
                <div className='button-container'>
                    <a href={jobLink} target="_blank" rel="noopener noreferrer">
                      <motion.button className="gradient-button" variants={joinUsButtonVariants}>JOIN US</motion.button>
                    </a>
                </div>
              </Card.Body>
            </motion.div>
          </div>
        </motion.div>
      }
      {!isLargerThanSplit && 
        <div className="engage">
          <div className="apply-container">
            <motion.div className="apply-background" variants={applyBackgroundVariants}>
              <Card className="apply">
                <Card.Body>
                  {/* <Card.Title>Our Companies</Card.Title> */}
                  <Card.Text>Learn more about our<br/>previous cohorts.</Card.Text>
                  <div className="apply-button-container">
                    <Link to="/companies"><motion.button className="white-button">OUR COMPANIES</motion.button></Link>
                    <motion.div className="apply-button-background" variants={applyButtonVariants}></motion.div>
                  </div>
                </Card.Body>
              </Card>
            </motion.div>
          </div>
          <motion.div className="engage-cap" initial="offscreen" whileInView="onscreen" viewport={{ once:true, amount:0.5}}>
            <motion.div className="join-us card" variants={joinUsCardVariants}>
              <Card.Body>
                {/* <Card.Title>Join Us</Card.Title> */}
                <Card.Text>Join the Wharton Cypher Accelerator community.</Card.Text>
                <div className='button-container'>
                  <a href={jobLink} target="_blank" rel="noopener noreferrer">
                    <motion.button className="gradient-button" variants={joinUsButtonVariants}>JOIN US</motion.button>
                  </a>
                </div>
              </Card.Body>
            </motion.div>
          </motion.div>
        </div>
      }
    </div>
  )
}

export default Engage;