import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import Carousel from 'react-bootstrap/Carousel';
import Stack from 'react-bootstrap/Stack'
import Image from 'react-bootstrap/Image';
import Marquee from "react-fast-marquee";
import { TwitterTweetEmbed } from 'react-twitter-embed';

import { MdSearchOff} from 'react-icons/md';
import $ from 'jquery';
import PageSlider from "../components/Page-Slider"
import { AnimatePresence, motion } from 'framer-motion';

import { HiAcademicCap } from 'react-icons/hi';
import { BiNews, BiPodcast } from 'react-icons/bi';
import { BsNewspaper, BsImage } from 'react-icons/bs';
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa';

import Footer from '../components/Footer';
import Navibar from '../components/Navibar';

import Newspaper from '../assets/images/newspaper.svg';
import { getArticles, getPapers, getTweets, getPodcasts, getVisuals } from '../components/modules/api';

import CypherLoadingVideo from '../assets/images/cypher-loading.mp4'
import CypherLoadingPoster from '../assets/images/cypher-loading-poster.png';
import CypherLoading from '../components/CypherLoading'

function News() {
  const [loadingState, setLoadingState] = useState(false)
  const [sectionSubtitle, setSectionSubtitle] = useState("Scroll through all of the relevant articles, visuals, and academic papers produced by and about Penn's Blockchain Community.");

  //Info
  const [articles, setArticles] = useState([]);
  const [articlesLoaded, setArticlesLoaded] = useState(false);
  const [papers, setPapers] = useState([]);
  const [papersLoaded, setPapersLoaded] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [podcastsLoaded, setPodcastsLoaded] = useState(false);
  const [visuals, setVisuals] = useState([]);
  const [visualsLoaded, setVisualsLoaded] = useState(false);


  const [radioState, setRadioState] = useState("All News")
  const [noArticles, setNoArticles] = useState(false);

 
  // const [bTweets, setBTweets] = useState();
  const [tTweets, setTTweets] = useState();
  const [recentPaper, setRecentPaper] = useState({title: "", tags : [], link: ""})
  const [recentPodcast, setRecentPodcast] = useState({title: "", tags : [], link: ""})
  const [recentVisual, setRecentVisual] = useState({title: "", tags : [], link: ""})
  const [recentArticle, setRecentArticle] = useState({title: "", publication: "", tags : [], link: ""})
  
  const [data, setData] = useState();
  const expertiseCheckboxes = [];
	const expertiseAreas = ["Cypher", "AI", "Robotics", "Quantum", "TradFi", "NFTs", "DAOs", "Regulation"];
	const expertiseCheckboxColors = ['#F174DD', '#74D3F1', '#F174B0', '#F1EC74', "#74F188", "#7E74F1", "#F1A874", "#74A6F1", "#74F1E2", '#b2cefe', '#baed91', '#CBB279'];
  
  const [checkboxArrayState, setCheckboxArrayState] = useState([]);
  //Build HTML Arrays
  const [liveArray, setLiveArray] = useState([]);
  const [newsArray, setNewsArray] = useState([]);
  const [searchOutputState, setSearchOutputState] = useState(false);
  const [builtPaperElements, setBuiltPaperElements] = useState([]);
  const [builtArticleElements, setBuiltArticleElements] = useState([]);
  const [builtPodcastElements, setBuiltPodcastElements] = useState([]);
  const [builtVisualElements, setBuiltVisualElements] = useState([]);


  // Ref Hooks
  const videoRef = useRef(null)
  const [tagNameList, setTagNameList] = useState(new Map([
    ['Cypher', new Set()],
    ['AI', new Set()],
    ['Robotics', new Set()],
    ['Quantum', new Set()],
    ['TradFi', new Set()],
    ['NFTs', new Set()],
    ['DAOs', new Set()],
    ['Regulation', new Set()],
    ['Articles', new Set()],
    ['Visuals', new Set()],
    ['Papers', new Set()],
    ['Podcasts', new Set()],
  ]));


  useEffect(() => {
    document.body.classList.add("background-static")
    setLoadingState(true)
    setTimeout(() => {
      setLoadingState(false)
      document.body.classList.remove("background-static")
      $('body,html').animate({ scrollTop: 0, }, 0);
    }, 10000)
	}, [])//10000
  // useEffect(() => {
  //   var loadingGifDiv = document.querySelector(".loading-page")
  //   if (loadingGifDiv && loadingState) {
  //       const { current: videoElement} = videoRef
  //       videoElement.setAttribute('muted', '')
  //   }
  // }, [loadingState])


  function updateNewsPageState() {
		var checkboxFilteredNames = [];
		var newLiveArray = [];

    checkboxArrayState.forEach(checkbox => {
      checkboxFilteredNames.push(...Array.from(tagNameList.get(checkbox)));
    })


		checkboxFilteredNames = [...new Set(checkboxFilteredNames)];
		switch (radioState) {
      case "Articles" :
        setSectionSubtitle("Scroll through relevant academic papers produced by Penn's Community.")
        if (!checkboxFilteredNames.length) {
          newLiveArray = builtArticleElements;
          break;
        }
        newLiveArray = builtArticleElements.filter(element => checkboxFilteredNames.includes(element.key));
        break;
      case "Papers" :
        setSectionSubtitle("Scroll through relevant articles produced by Penn's Community.")
        if (!checkboxFilteredNames.length) {
          newLiveArray = builtPaperElements;
          break;
        }
        newLiveArray = builtPaperElements.filter(element => checkboxFilteredNames.includes(element.key));
        break;
      case "Podcasts" :
        setSectionSubtitle("Scroll through relevant podcasts produced by Penn's Community.")
        if (!checkboxFilteredNames.length) {
          newLiveArray = builtPodcastElements; 
          break;
        }
        newLiveArray = builtPodcastElements.filter(element => checkboxFilteredNames.includes(element.key));
        break;
      case "Visuals" :
        setSectionSubtitle("Scroll through relevant visuals produced by Penn's Community.")
        if (!checkboxFilteredNames.length) {
          newLiveArray = builtVisualElements;
          break;
        }
      case "All News" :
        setSectionSubtitle("Scroll through relevant papers and thought leadership produced by Penn’s community.")
        if (!checkboxFilteredNames.length) {
          newLiveArray = newsArray;
          break;
        }
        newLiveArray = newsArray.filter(element => checkboxFilteredNames.includes(element.key));
        break;
      default :
        break;
    }

    newLiveArray = newLiveArray.sort(function(a, b) {
      var aord = +a.props.id;
      var bord = +b.props.id;
      return (aord < bord) ? 1 : -1;
    })
    setNoArticles(false)
    setLiveArray(newLiveArray);
    
    if (checkboxArrayState.length && !checkboxFilteredNames.length) {
      setNoArticles(true)
      setSearchOutputState(false);
    } else {
      setSearchOutputState(true);
    }
	}

  useEffect(() => {
		updateNewsPageState();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [radioState, checkboxArrayState]);

  

  useEffect(() => {
    let tempTagList = tagNameList; 
    articles.forEach(article => {
      let tempArticleSet = tempTagList.get("Articles");
      tempArticleSet.add(article.title);
      tempTagList.set("Articles", tempArticleSet);
      article.tags.forEach(tag => {
        let tempSet = tempTagList.get(tag);
        tempSet.add(article.title);
        tempTagList.set(tag, tempSet);
      })
    });

    papers.forEach(paper => {
      let tempPaperSet = tempTagList.get("Papers");
      tempPaperSet.add(paper.title);
      tempTagList.set("Papers", tempPaperSet);
      paper.tags.forEach(tag => {
        let tempSet = tempTagList.get(tag);
        tempSet.add(paper.title);
        tempTagList.set(tag, tempSet);
      })
    });

    podcasts.forEach(podcast => {
      let tempPodcastSet = tempTagList.get("Podcasts");
      tempPodcastSet.add(podcast.title);
      tempTagList.set("Podcasts", tempPodcastSet);
      podcast.tags.forEach(tag => {
        let tempSet = tempTagList.get(tag);
        tempSet.add(podcast.title);
        tempTagList.set(tag, tempSet);
      })
    });

    visuals.forEach(visual => {
      let tempVisualSet = tempTagList.get("Visuals");
      tempVisualSet.add(visual.title);
      tempTagList.set("Visuals", tempVisualSet);
      visual.tags.forEach(tag => {
        let tempSet = tempTagList.get(tag);
        tempSet.add(visual.title);
        tempTagList.set(tag, tempSet);
      })
    });

    setTagNameList(tempTagList);
  }, [articles, papers, podcasts, visuals, tagNameList])

  useEffect(() => {
    function buildTweets(data) {
      // const tweetElementsBottom = [];
      const tweetElementsTop = [];
      data.forEach((tweet, index) => {
        const tweetElement = 
          <div key={index} className="centerContent">
            <div className="selfCenter spaceBetween">
              <TwitterTweetEmbed
                onLoad={function noRefCheck(){}}
                options={{
                  cards: 'hidden'
                }}
                tweetId={tweet}
              />
            </div>
          </div>
          tweetElementsTop.push(tweetElement);
        
      })
      setTTweets(tweetElementsTop)
    } 
    getTweets().then(ret=> buildTweets(ret))
  }, [])


  useEffect(() => {
    let tempArray = builtPaperElements.concat(builtArticleElements, builtPodcastElements, builtVisualElements);
    tempArray = tempArray.sort(function(a, b) {
      var aord = +a.props.id;
      var bord = +b.props.id;
      return (aord < bord) ? 1 : -1;
    })
    setLiveArray(tempArray);
    setNewsArray(tempArray);
  }, [builtArticleElements, builtPaperElements, builtPodcastElements, builtVisualElements])



  useEffect(() => {
    if(!papersLoaded) {
      getPapers().then(ret=> setPapers(ret))
      setPapersLoaded(true)
    }
   
  }, [papersLoaded])
  
  useEffect(() => {
    if(!articlesLoaded) {
      getArticles().then(ret=> setArticles(ret))
      setArticlesLoaded(true)
    }
  }, [articlesLoaded])

  useEffect(() => {
    if(!podcastsLoaded) {
      getPodcasts().then(ret=> setPodcasts(ret))
      setPodcastsLoaded(true)
    }
  }, [podcastsLoaded])

  useEffect(() => {
    if(!visualsLoaded) {
      getVisuals().then(ret=> setVisuals(ret))
      setVisualsLoaded(true)
    }
  }, [visualsLoaded])

  useEffect(() => {
    const colors = new Map([
      ['Cypher', '#F174DD'],
      ['AI', '#74D3F1'],
      ['Robotics', '#F174B0'],
      ['Quantum', '#F1EC74'],
      ['TradFi', '#74F188'],
      ['NFTs', '#7E74F1'],
      ['DAOs', '#74F1E2'],
      ['Regulation', '#74A6F1'],
      ['Default', '#00A6FC']
    ]);    

    const podcastElements = [];
    podcasts.forEach(element => {
      const tags = element.tags.map((tag) => {
        if(colors.has(tag)) {
          return <Badge pill key={tag} style={{backgroundColor: colors.get(tag), color: 'black'}} bg="">
            {tag}
          </Badge>
        } else {
          return <Badge pill key={tag} style={{backgroundColor: colors.get("Default"), color: 'black'}} bg="">
          {tag}
        </Badge>
        }
      }
        
      );
      const podcastElement = (
        <div className="newspiece" id={element.time} key={element.title}>
          <div className="newspiece-container">
            <Card  style={{ width: '18rem'}} onClick={()=> window.open(element.link)} id='articles'>
              <Card.Img src={element.imgLink}/>
              <Card.Body>
                  <Card.Title>{element.title}</Card.Title>
                  <Card.Subtitle>{element.publication}</Card.Subtitle>
                  <Stack direction="horizontal" gap={2}>
                    {tags}
                  </Stack>
                  <div className='research-icon'>
                    <BiPodcast style={{color: "white"}}size={40}/>
                  </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )
      podcastElements.push(podcastElement);
      
      if(element.time === podcasts.length) {
        setRecentPodcast(element)
      }
    });
      setBuiltPodcastElements(podcastElements);
  
  }, [podcasts])


  useEffect(() => {
    const colors = new Map([
      ['Cypher', '#F174DD'],
      ['AI', '#74D3F1'],
      ['Robotics', '#F174B0'],
      ['Quantum', '#F1EC74'],
      ['TradFi', '#74F188'],
      ['NFTs', '#7E74F1'],
      ['DAOs', '#74F1E2'],
      ['Regulation', '#74A6F1'],
      ['Default', '#00A6FC']
    ]);  

    const articlesElements = [];
    articles.forEach(element => {
      const tags = element.tags.map((tag) => {
        if(colors.has(tag)) {
          return <Badge pill key={tag} style={{backgroundColor: colors.get(tag), color: 'black'}} bg="">
            {tag}
          </Badge>
        } else {
          return <Badge pill key={tag} style={{backgroundColor: colors.get("Default"), color: 'black'}} bg="">
          {tag}
        </Badge>
        }
      }
        
      );
      const articleElement = (
        <div className="newspiece" id={element.time} key={element.title}>
          <div className="newspiece-container">
            <Card  style={{ width: '18rem'}} onClick={()=> window.open(element.link)} id='articles'>
              <Card.Img src={element.imgLink}/>
              <Card.Body>
                  <Card.Title>{element.title}</Card.Title>
                  <Card.Subtitle>{element.publication}</Card.Subtitle>
                  <Stack direction="horizontal" gap={2}>
                    {tags}
                  </Stack>
                  <div className='research-icon'>
                    <BiNews style={{color: "white"}}size={40}/>
                  </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )
      articlesElements.push(articleElement);
      
      if(element.time === articles.length) {
        setRecentArticle(element)
      }
    });
      setBuiltArticleElements(articlesElements);
  
  }, [articles])


  useEffect(() => {
      const paperElements = [];
      papers.forEach((element, index)=> {
        const tags = element.tags.map((tag) => 
            <Badge pill key={tag} style={{backgroundColor: 'white', color: 'black', border: '2px solid black'}} bg="">
              {tag}
            </Badge>
        );
        const paperElement = (
          <div className="newspiece" key={element.title} id={element.time} onClick={()=> window.open(element.link)}>
            <div className="newspiece-container">
              <Card id='papers'>
                <Card.Body>
                  <Card.Title>{element.title}</Card.Title>
                  <Stack direction="horizontal" gap={2}>
                    {tags}
                  </Stack>
                  <div className='research-icon'>
                    <HiAcademicCap style={{color: "white"}} size={40}/> 
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )
        paperElements.push(paperElement)

        if(element.time === papers.length) {
          setRecentPaper(element)
        }
      });
      
      setBuiltPaperElements(paperElements);
  }, [papers])

  useEffect(() => {
      const visualElements = [];
      visuals.forEach((element, index)=> {
        const tags = element.tags.map((tag) => 
            <Badge pill key={tag} style={{backgroundColor: 'white', color: 'black', border: '2px solid black'}} bg="">
              {tag}
            </Badge>
        );
        const visualElement = (
          <div className="newspiece" key={element.title} id={element.time} onClick={()=> window.open(element.link)}>
            <div className="newspiece-container">
              <Card id='visuals'>
                <Card.Body>
                  <Card.Title>{element.title}</Card.Title>
                  <Stack direction="horizontal" gap={2}>
                    {tags}
                  </Stack>
                  <div className='research-icon'>
                    <BsImage style={{color: "white"}}size={40}/>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )
        visualElements.push(visualElement)

        if(element.time === visuals.length) {
          setRecentVisual(element)
        }
      });
      
      setBuiltVisualElements(visualElements);
  }, [visuals])

  useEffect(() => {
    setData(
      <>
        <div className="secondary-page-header" >
          {/* <Image src={Newspaper}/> */}
          <h1 className='section-title-text'>Our Thought Leadership.</h1>
        </div>
        <RecentCarousel article={recentArticle} paper={recentPaper} podcast={recentPodcast}/>
      </>
      
    )
  }, [recentArticle, recentPaper, recentPodcast])


  function getCheckboxValues() {
		var expertiseCheckboxes = document.getElementsByClassName("expertise-pill-checkbox");
    
		var tempCheckboxArray = [];
		for (var i = 0; i < expertiseCheckboxes.length; i++) {
			if (expertiseCheckboxes[i].checked) {
				tempCheckboxArray.push(expertiseCheckboxes[i].value);
			}
		}

		return tempCheckboxArray;
	}

  for (var iter in expertiseAreas) {
		expertiseCheckboxes.push(<span key={iter} className="expertise-pill">
			<input className="expertise-pill-checkbox" type="checkbox" id={expertiseAreas[iter]} value={expertiseAreas[iter]} onChange={(evt) => {setCheckboxArrayState(getCheckboxValues)}}/>
			<label className="expertise-pill-text" style={{backgroundColor: expertiseCheckboxColors[iter]}} htmlFor={expertiseAreas[iter]}>{expertiseAreas[iter]}</label>
		</span>)
	}

  return (
    <>
      <div className="news">
        <AnimatePresence style={{width: '100vw'}}>
                  {loadingState && <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {
            duration: 0.5, type: "ease"}}}>
              <div className="cypher-loading">
                            <CypherLoading/>
                        </div>
                          {/* <video className="loading-video" src={CypherLoadingVideo} ref={videoRef} poster={CypherLoadingPoster} preload={'auto'} loop autoPlay playsInline muted type="video/mp4"></video> */}
                      </motion.div>
                  }
        </AnimatePresence>
        <PageSlider />
        <Navibar secondaryPage={true}/>
        <div className='news-header-container'>
          <div className='news-header' >
            {data}
          </div>
        </div>

        <div className="highlight-container">
          <div className="left-side">
            <div className="section-subtitle-text">Currently building a</div>
            <div className="section-title-text">Bank Liquidity<br/>Risk Model</div>
          </div>
          <div className="right-side">
            <h3 className="upper-text">
            Cypher is developing a ML model to identify leading indicators of regional bank liquidity crises through social media analysis.
            </h3>
            <h6 className="lower-text">
            The project involves using historical Reddit and Twitter data archives and various NLP techniques to identify risk language associated with bank runs. The data is then labeled with historical liquidity data and used in a time-series model to predict the probability of an incoming liquidity event.
            </h6>
          </div>
        </div>

        
        <div className='articles'>
          <div className='news-checkboxes-containers'>
            <div className='news-checkboxes'>
                <div className='panel-description'>What do you want to learn about?</div>
                <div className='news-checkbox-stacks'>
                  <div className="team-radio-group" onChange={(evt) => {setRadioState(evt.target.value)}}>
                    <label className="news-radio">
                      <input type="radio" value="Papers" name="radio" className="news-radio-input"/>
                      <div className="news-radio-button"></div>
                      <div className='radio-news-container'>
                        <div>
                          <HiAcademicCap style={{color: "black"}} size={20}/>
                        </div>
                        <div>Papers</div>
                      </div>
                    </label>
                    <label className="news-radio">
                      <input type="radio" value="Visuals" name="radio" className="news-radio-input"/>
                      <div className="news-radio-button"></div>
                      <div className='radio-news-container'>
                        <div>
                          <BsImage style={{color: "black"}}size={20}/>
                        </div>
                        <div>Visuals</div>
                      </div>
                    </label>
                    <label className="news-radio">
                      <input type="radio" value="Articles" name="radio" className="news-radio-input"/>
                      <div className="news-radio-button"></div>
                      <div className='radio-news-container'>
                        <div>
                          <BiNews style={{color: "black"}}size={20}/>
                        </div>
                        <div>Articles</div>
                      </div>
                    </label>
                    <label className="news-radio">
                      <input type="radio" value="Podcasts" name="radio" className="news-radio-input"/>
                      <div className="news-radio-button"></div>
                      <div className='radio-news-container'>
                        <div>
                          <BiPodcast style={{color: "black"}} size={20}/>
                        </div>
                        <div>Podcasts</div>
                      </div>
                    </label>
                    <label className="news-radio">
                      <input type="radio" value="All News" name="radio" className="news-radio-input" defaultChecked/>
                      <div className="news-radio-button"></div>
                      <div className='radio-news-container'>
                        <div>
                          <BsNewspaper style={{color: "black"}} size={20}/>
                        </div>
                        <div>All News</div>
                      </div>
                    </label>
                  </div>
                <div className='vl'></div>
                <Stack className='type-specific-stack' gap={1} direction="horizontal">{expertiseCheckboxes}</Stack>
                </div>             
            </div>
          </div>
        </div>
        <div className='news-description'>
          {sectionSubtitle}
        </div>
        {searchOutputState ? <div className="scroll-through" id="news-section-articles"><Stack direction="horizontal" gap={5}>{liveArray}</Stack></div> : <div className="no-search-output">
            <hr className="top-bar"/>
            <MdSearchOff/>
            <div className="no-search-output-text">No search results found.</div>
            <hr className="bottom-bar"/>
				</div>}
        
      </div>
      <div className='twitter'>
        <div className='subsection-title-text'>Join the conversations.</div>
        <Marquee gradient={false}> 
          {tTweets}
        </Marquee>
      </div>
      <Footer/>

    </>
  );
}


// // twttr.widgets.createTweet:
// // https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function

// // Embedded Tweet parameter reference
// // https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference



// https://publish.twitter.com/oembed?url=https://twitter.com/FinanceHammer/status/1555839553142788096&hide_media=true
// https://elmah.io/tools/base64-image-encoder/
// https://www.img2go.com/result#j=aad8a5d7-9d1b-4974-91ce-ecbe05e10c11
// https://www.npmjs.com/package/react-pdf

function RecentCarousel(props) {
  const colors = new Map([
    ['Cypher', '#F174DD'],
    ['AI', '#74D3F1'],
    ['Robotics', '#F174B0'],
    ['Quantum', '#F1EC74'],
    ['TradFi', '#74F188'],
    ['NFTs', '#7E74F1'],
    ['Partnerships', '#74F1E2'],
    ['Engineering', '#74A6F1'],
    ['Wharton', '#F17474'],
    ['Regulation', '#74A6F1'],
    ['Default', '#00A6FC']
  ]);
  // const paperTags = props.paper.tags.map((tag) => 
  //   <Badge pill key={tag} style={{backgroundColor: 'white', color: 'black', border: '2px solid black'}} bg="">
  //     {tag}
  //   </Badge>
    
  // );

  const paperTags = props.paper.tags.map((tag) => {
    if(colors.has(tag)) {
      return <Badge pill key={tag} style={{backgroundColor: colors.get(tag), color: 'black'}} bg="">
        {tag}
      </Badge>
    } else {
      return <Badge pill key={tag} style={{backgroundColor: colors.get("Default"), color: 'black'}} bg="">
      {tag}
    </Badge>
    }
  });

  const articleTags = props.article.tags.map((tag) => {
      if(colors.has(tag)) {
        return <Badge pill key={tag} style={{backgroundColor: colors.get(tag), color: 'black'}} bg="">
          {tag}
        </Badge>
      } else {
        return <Badge pill key={tag} style={{backgroundColor: colors.get("Default"), color: 'black'}} bg="">
        {tag}
      </Badge>
      }
  })

  const podcastTags = props.article.tags.map((tag) => {
    if(colors.has(tag)) {
      return <Badge pill key={tag} style={{backgroundColor: colors.get(tag), color: 'black'}} bg="">
        {tag}
      </Badge>
    } else {
      return <Badge pill key={tag} style={{backgroundColor: colors.get("Default"), color: 'black'}} bg="">
      {tag}
    </Badge>
    }
})

  const nextIcon = <FaChevronRight size={30}/>
  const prevIcon = <FaChevronLeft size={30}/>
  
  return (
  <div style={{ display: 'block', paddingBottom: 30 }}>
      <Carousel nextIcon ={nextIcon} prevIcon={prevIcon} touch={true}>
        <Carousel.Item interval={5000}>
          <Card id='recentPaper' style={{height: '20vw'}}  onClick={()=> window.open(props.paper.link)}>
            <Card.Body>
              <Card.Title>{props.paper.title} </Card.Title>
              <Stack direction="horizontal" gap={2}>
                {paperTags}
              </Stack>
              <div className='research-icon'>
                <HiAcademicCap style={{color: "white"}} size={40}/>
              </div>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Card id='recentArticle' style={{height: '20vw'}} onClick={()=> window.open(props.article.link)}>
            <Card.Body>
              <Card.Title>{props.article.title}</Card.Title>
              <Card.Subtitle>{props.article.publication}</Card.Subtitle>
              <Stack direction="horizontal" gap={2}>
                {articleTags}
              </Stack>
              <div className='research-icon'>
                <BiNews style={{color: "white"}}size={40}/>
              </div>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Card id='recentPodcast' style={{height: '20vw'}} onClick={()=> window.open(props.podcast.link)}>
            <Card.Body>
              <Card.Title>{props.podcast.title}</Card.Title>
              <Stack direction="horizontal" gap={2}>
                {podcastTags}
              </Stack>
              <div className='research-icon'>
                <BiPodcast style={{color: "white"}}size={40}/>
              </div>
            </Card.Body>
          </Card>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default News;