import React from "react"
import {motion} from "framer-motion"
import "./Component.css"

function PageSlider() {
  // Animation Variants
  const sliderLeftVariants = {
    initial: {transform: "translateX(-60vw)"},
    exit: {transform: "translateX(0vw)", transition: {duration: 1, type: "ease"}}
  }
  const sliderRightVariants = {
    initial: {transform: "translateX(60vw)"},
    exit: {transform: "translateX(0vw)", transition: {duration: 1, type: "ease"}}
  }

  // Render Method
  return (
    <div className="slider-container">
      <motion.div className="slider left-side" initial="initial" exit="exit" variants={sliderLeftVariants}/>
      <motion.div className="slider right-side" initial="initial" exit="exit" variants={sliderRightVariants}/>
    </div>
  )
}

export default PageSlider;