import React, {useState, useEffect, useLayoutEffect, useRef} from "react"
import { motion, AnimatePresence } from 'framer-motion'

import Navibar from './components/Navibar';
import Hero from './components/Hero';
import Information from './components/Information';
import Engage from './components/Engage';
import Team from './components/Team';
import Cohort from './components/Cohort';
import Projects from './components/Projects';
import Footer from './components/Footer';
import Partners from './components/Partners';
import PageSlider from './components/Page-Slider'

import CypherLoadingVideo from './assets/images/cypher-loading.mp4'
import CypherLoadingPoster from './assets/images/cypher-loading-poster.png'
import CypherLoading from './components/CypherLoading'

function Home() {
    // State Hooks
    const [loadingState, setLoadingState] = useState(false)

    // Ref Hooks
    const videoRef = useRef(null)

    // Effect Hooks
    useEffect(() => {
        // const videoList = [CypherLoadingVideo];
        // videoList.forEach((video) => {
        //     const newVideo = document.createElement("video")
        //     newVideo.src = video;
        //     window[video] = newVideo;
        // })
        window.scrollTo({
            top: 0, left: 0, behavior: "auto"
        })
        document.querySelector(".home-contents").style.display = "none"
		document.body.classList.add("background-static")
		setLoadingState(true)
		setTimeout(() => {
            document.querySelector(".home-contents").style.display = "block"
			setLoadingState(false)
			document.body.classList.remove("background-static")
		}, 5000)
	}, [])
    // useEffect(() => {
    //     var loadingGifDiv = document.querySelector(".loading-page")
    //     // if (loadingGifDiv && loadingState) {
    //     //     const { current: videoElement} = videoRef
    //     //     videoElement.setAttribute('muted', '')
    //     // }
    // }, [loadingState])
    // useEffect(() => {
    //     var loadingGifDiv = document.querySelector(".loading-page")
    //     if (loadingGifDiv && loadingState) {
    //         loadingGifDiv.style.backgroundImage = "none"
    //         setTimeout(() => {
    //             loadingGifDiv.style.backgroundImage = "url(" + CypherLoading + "?a=" + Math.random() +")"
    //         }, 0)
    //     }
    // }, [loadingState])

    
    // Render Method
    return (
        <div className="home">
            <AnimatePresence>
                {loadingState && <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {
					duration: 0.5, type: "ease"}}}>
                        <div className="cypher-loading">
                            <CypherLoading/>
                        </div>
                        {/* <video className="loading-video" src={CypherLoadingVideo} ref={videoRef} poster={CypherLoadingPoster} preload={'auto'} loop autoPlay playsInline muted type="video/mp4"></video> */}
                    </motion.div>
                }
			</AnimatePresence>
            {/* <AnimatePresence>
				{loadingState && <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {
					duration: 0.5, type: "ease"}}}></motion.div>}
			</AnimatePresence> */}
            <PageSlider/>
            <div className="home-contents">
                <Navibar secondaryPage={true}/>
                <Hero/>
                <Information/>
                <Cohort/>
                <Engage/>
                <Team/>
                <Projects/>
                <Partners/>
                <Footer/>
            </div>
        </div>
    );
  }

export default Home;