import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import News from '../pages/News.Page'
import TeamPage from '../pages/Team.Page'
import CompaniesPage from '../pages/Companies.Page'
import Home from "../Home"
import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() {
  const location = useLocation();
  
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/companies" element={<CompaniesPage />}/>
        <Route path="/news" element={<News />}/>
        <Route path="/team" element={<TeamPage />}/>
        <Route path="/" element={<Home />}/>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes;