import React, { useEffect } from 'react';
import GigaMap from "../assets/images/projects/giga_map_desktop.png"
import Stack from 'react-bootstrap/Stack'
import { motion } from "framer-motion";
import { gigaLink } from '../utilities/constants';

function Projects() {
  // Animation Variants
  const titleVariants = {
    offscreen: {
      x: 1000
    },
    onscreen: {
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    }
  };

  // Hooks
  useEffect(() => {
    const imageList = [GigaMap];
    imageList.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    })
  });

  // Render Component
  return (
    <div className="projects" >
      <div className='flex-container' id="projects" >
        <div className='flex-child' id='intro-right'>
          <motion.div className="projects-text" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
            <motion.div style={{border: 0}} variants={titleVariants}>
              <div className="section-subtitle-text">Collaborating with a UNICEF-ITU initiative</div>
              <div className="section-title-text">PROJECT GIGA</div>
            </motion.div>
          </motion.div>
          <div className="map">
            <img src={GigaMap}/>
          </div>
          <motion.div className="subsection-title-text">CONNECTING EVERY SCHOOL TO THE INTERNET.</motion.div>
          <motion.div className="description">Cypher is working with Giga's corporate and non-profit partners to map schools' Internet access in real time, create models for innovative financing, and support governments contracting for connectivity.</motion.div>
          <div className="engage-buttons">
          <a href={gigaLink} target="_blank" rel="noopener noreferrer"><button className="black-button">LEARN MORE</button></a>
        </div>        
        </div>
      </div>
    </div>
  );
}

export default Projects;