import React from 'react';
import { FaTwitterSquare, FaLinkedin } from 'react-icons/fa';
import { TbWorld } from 'react-icons/tb';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import cohort from '../assets/cohort.json';

export default function MyVerticallyCenteredModal(props) {
  const data = cohort;
  const style = {
    border: '2px solid black',
    borderRadius : '5px',
    background: 'white'
  };  
  const stackStyle = {
    backgroundColor: 'transparent'
  };  

  const companies = props.companyarray.map((company, index) => {
    console.log("Company key:", company);
        console.log("Company data:", data[company]);

        // Check if data for the company exists to avoid undefined errors
        if (!data[company]) {
            console.warn(`Data for company '${company}' not found.`);
            return null; // Or handle the undefined case as needed
        }
    const tags = data[company].tags.map((tag) =>
      <Badge pill key={tag} style={{backgroundColor:  data[company].color, color: 'black'}} bg="">
        {tag}
      </Badge>
    );
      return <div className='flex-container' style={style} key={company} id='modal'>
        <div className='flex-child' id='popup-img'>
          <span className="helper"></span>
          <img src={require(`../assets/images/companies/${cohort[company].logo}.png`)} alt=''/>
        </div>
        <div className='flex-child' id='popup-modal'>
          <Modal.Header closeButton={index === 0}>
            <Modal.Title id="contained-modal-title-vcenter">{company}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{cohort[company].description}</p>
            <p><b>{cohort[company].location}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <div>{tags}</div>
            {data[company].website && <a href={data[company].website} target="_blank" rel="noopener noreferrer"> <TbWorld size={30}/></a>}
            {data[company].LinkedIn && <a href={data[company].LinkedIn} target="_blank" rel="noopener noreferrer"> <FaLinkedin size={30}/></a>}
            {data[company].Twitter && <a href={data[company].Twitter} target="_blank" rel="noopener noreferrer"> <FaTwitterSquare size={30}/></a>}
          </Modal.Footer>
        </div>
      </div>
  });
  
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Stack direction="vertical" gap={3} style={stackStyle}>
        {companies}
      </Stack>
    </Modal>
  );
}