import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase';

async function getArticles() {
    return await new Promise((resolve, reject) => {
        const ref = collection(db, 'articles')
        getDocs(ref).then(response => {
            const data = response.docs.map(doc => (
                doc.data()
            ))
            resolve(data)
        }).catch(err => reject(err))
    })
}

async function getPapers() {
    return await new Promise((resolve, reject) => {
        const ref = collection(db, 'papers')
        getDocs(ref).then(response => {
            const data = response.docs.map(doc => (
                doc.data()
            ))
            resolve(data)
        }).catch(err => reject(err))
    })
}

async function getPodcasts() {
    return await new Promise((resolve, reject) => {
        const ref = collection(db, 'podcasts')
        getDocs(ref).then(response => {
            const data = response.docs.map(doc => (
                doc.data()
            ))
            resolve(data)
        }).catch(err => reject(err))
    })
}

async function getVisuals() {
    return await new Promise((resolve, reject) => {
        const ref = collection(db, 'visuals')
        getDocs(ref).then(response => {
            const data = response.docs.map(doc => (
                doc.data()
            ))
            resolve(data)
        }).catch(err => reject(err))
    })
}

async function getTweets() {
    return await new Promise((resolve, reject) => {
        const ref = collection(db, 'tweets')
        getDocs(ref).then(response => {
            const data = response.docs.map(doc => (
                doc.data().id
            ))
            resolve(data)
        }).catch(err => reject(err))
    })
}


export {
    getArticles,
    getPapers, 
    getTweets,
    getPodcasts,
    getVisuals
};
