import { React } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive'

function Partners() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 765px)' })
  let counter = 0; 
  const cardVariants = {
    hidden: { path: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.25;
      return {
        opacity: [0, 1, .5],
        transition: {
          opacity: { delay, duration: 0.4 }
        }
      };
    }
  };

  function increment() {
    counter += .4;
    return counter;
  }

  return (
    <motion.div 
      className="partners"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.05 }}
    >
      <div className="subsection-title-text">Corporate Partners</div>
      <Container>
        <Row xs={2} md={4}>
          {/* <Col md={{span: 2, offset: 2}}><a href='https://polygon.technology/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='polygon' src={require('../assets/images/partners/polygon.png')} alt=''/></a></Col> */}
          <Col><a href='https://www.mckinsey.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='mckinsey' src={require('../assets/images/partners/mckinsey.png')} alt=''/></a></Col>
          <Col><a href='https://www.blockdaemon.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='blockdaemon' src={require('../assets/images/partners/blockdaemon.png')} alt=''/></a></Col>
          <Col><a href='https://securitize.io/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }}style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='securitize' src={require('../assets/images/partners/securitize.png')} alt=''/></a></Col>
          <Col><a href='https://www.bcg.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='bcg' src={require('../assets/images/partners/bcg.png')} alt=''/></a></Col>
          {/* <Col><a href='https://www.money2020.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='money2020' src={require('../assets/images/partners/money2020.png')} alt=''/></a></Col> */}
        </Row>
        <Row xs={2} md={4}>
          <Col><a href='https://aws.amazon.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={counter} className='partner-logo' id='aws' src={require('../assets/images/partners/aws.png')} alt=''/></a></Col>
          <Col><a href='https://celo.org/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='money2020' src={require('../assets/images/partners/celo.png')} alt=''/></a></Col>
          <Col><a href='https://about.google/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='money2020' src={require('../assets/images/partners/google.png')} alt=''/></a></Col>
          <Col><a href='https://chain.link/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='chainlink' src={require('../assets/images/partners/chainlink.png')} alt=''/></a></Col>
        </Row>
      </Container>

      <div className="subsection-title-text">Collaborators</div>
      <Container>
        <Row xs={3} md={5}>
          <Col><a href='https://ai.ethz.ch/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='ethai' src={require('../assets/images/partners/eth_ai.png')} alt=''/></a></Col>
          <Col><a href='https://www.merantix.com/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='merantix' src={require('../assets/images/partners/merantix.png')} alt=''/></a></Col>
          <Col><a href='https://www.worldbank.org/en/home' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }}style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='worldbank' src={require('../assets/images/partners/worldbank.png')} alt=''/></a></Col>
          <Col><a href='https://www.ifc.org/en/home' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='ifc' src={require('../assets/images/partners/ifc.png')} alt=''/></a></Col>
          <Col><a href='https://www.conceptionx.org/' target="_blank" rel="noopener noreferrer"><motion.img whileHover={{ opacity: 1 }} style={{ opacity: .5 }} variants={cardVariants} custom={increment()} className='partner-logo' id='conceptionx' src={require('../assets/images/partners/conceptionx.png')} alt=''/></a></Col>
        </Row>
      </Container>
    </motion.div>

    
  );
}

export default Partners;