import React from "react";
import { motion } from "framer-motion"
import Member from './Member'
import CypherTeam from '../assets/cypher-team.json'
import Advisors from '../assets/advisors.json'

function Team() {
  // Member Card Arrays
  const CypherTeamMembers = [];
  const AdvisorMembers = [];

  // Member Card Counters
  let cypherCounter = -1;
  let advisorCounter = -1;

  // Animation Variants
  const titleVariants = {
    offscreen: {x: -800},
    onscreen: {x: 0, transition: {type: "spring", bounce: 0.4, duration: 1}}
  }
  const cypherCardVariants = {
    offscreen: {x: 200, opacity: 0},
    onscreen:(i) => {return {
      x: 0, opacity: 1, transition: {
      type: "ease-out", duration: 1, delay: i * 0.2
    }}}
  }
  const advisorCardVariants = {
    offscreen: {x: -200, opacity: 0},
    onscreen:(i) => {return {
      x: 0, opacity: 1, transition: {
      type: "ease-out", duration: 1, delay: i * 0.2
    }}}
  }
  const subsectionTitleVariants = {
    offscreen: {backgroundPosition: "right"},
    onscreen: {backgroundPosition: "left", transition: {type: "ease", duration: 0.5}}
  }

  // Functions
  function cypherIncrement() {
    cypherCounter += 1;
    return cypherCounter;
  }
  function advisorIncrement() {
    advisorCounter += 1;
    return advisorCounter;
  }

  // Executed Code
  for (var key in CypherTeam) {
    CypherTeamMembers.push(<motion.div key={key} custom={cypherIncrement()} variants={cypherCardVariants}>
      <Member name={key} key={key} type="Cypher" data={CypherTeam[key]} badgeColors={[]} showBadges={false} secondaryPage={false}></Member>
    </motion.div>)
  }
  for (var key in Advisors) {
    AdvisorMembers.push(<motion.div key={key} custom={advisorIncrement()} variants={advisorCardVariants}>
      <Member name={key} key={key} type="Advisor" data={Advisors[key]} badgeColors={[]} showBadges={false} secondaryPage={false}></Member>
    </motion.div>)
  }
  
  // Render Method
  return (
    <div className="team">
      <motion.div className="team-text" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8}}>
        <motion.div style={{border: 0}} variants={titleVariants}>
          <div className="section-subtitle-text">We are here to help.</div>
          <div className="section-title-text">Meet Our Team.</div>
        </motion.div>
      </motion.div>
      <motion.div className="cypher-team" initial="offscreen" whileInView="onscreen" viewport={{ once:true, amount:0.5}}>
        {/* <motion.div className="subsection-title-text" variants={subsectionTitleVariants}>Cypher Managers</motion.div> */}
        <motion.div className="subsection-title-text">CYPHER MANAGERS</motion.div>
        <div className="scroll-through">{CypherTeamMembers}</div>
      </motion.div>
      <motion.div className="senior-advisors" initial="offscreen" whileInView="onscreen" viewport={{ once:true, amount:0.5}}>
        <motion.div className="subsection-title-text" variants={subsectionTitleVariants}>SENIOR ADVISORS</motion.div>
        <div className="scroll-through">{AdvisorMembers}</div>
      </motion.div>
    </div>
  ) 
}

export default Team;