// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// dotenv.config()

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYxrej5Ir1Mh-zMOAIWOiMtHTd7SmbE2g",
  authDomain: "cypher-accelerator.firebaseapp.com",//cypher-accelerator.firebaseapp.com
  databaseURL: "https://cypher-accelerator-default-rtdb.firebaseio.com",
  projectId: "cypher-accelerator",
  storageBucket: "cypher-accelerator.appspot.com",
  messagingSenderId: "392385018798",
  appId: "1:392385018798:web:ca08e1b1ea79068bc8263f",
  measurementId: "G-S5K06Z47FR"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
// try {
//   const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider('6LcJJ5kiAAAAAKExEuK-ynR6h6JQ_EBmevN2Bhva'),
  
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });
//   // console.log(appCheck);
// } catch (e) {
//   console.log("not a verified app")
// }


export const db = getFirestore(app);
export const storage = getStorage(app);