import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import cohort from '../assets/cohort.json'
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';

export function Company(props) {
  const [modalShow, setModalShow] = useState(false);
  const name = props.name;
  const data = cohort;
  const color = data[name].color;
  const tags = data[name].tags.map((tag) =>
    <Badge pill key={tag} style={{backgroundColor: color, color: 'black'}} bg="">
      {tag}
    </Badge>
  );
  const logo = data[name].logo;
  
  return (
    <div className="company">
      <div className='company-container'>
        <Card onClick={() => setModalShow(true)} className='company-card'>
          <Card.Body>
            <div className='flex-container' id='company-card'>
              <div className='flex-child' id='company-logo'>
                <span className="helper" id='company-logo'></span>
                <img src={require(`../assets/images/companies/${logo}.png`)} alt=''/>
              </div>
              <div className='flex-child' id='company-info'>
                <Card.Title><b>{name}</b></Card.Title>
                <div className='flex-container' id='card-info'>
                  <div className='flex-child' id='tags'>
                    {tags}     
                  </div>
                  {/* Company Social Media Buttons */}
                  {/* <div className='flex-child' id='company-links'>
                    <Stack direction="horizontal" gap={1}>
                      {data[name].website && <a href={data[name].website}> <TbWorld size={30}/></a>}
                      {data[name].Twitter && <a href={data[name].Twitter}><FaTwitterSquare size={30}/></a>}
                    </Stack>
                  </div> */}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        companyarray={[name]}
      />
    </div>
  );
}
  
  