import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import $ from 'jquery';

import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

import 'swiper/css';

SwiperCore.use([EffectCoverflow, Pagination]);

function Information() {
  // Media Query
  const isLargerThanSplit = useMediaQuery({ query: '(min-width: 1000px)'});
  const isLargerThanCardCenterSplit = useMediaQuery({ query: '(min-width: 550px)'})
  const isLargerThanSmallPhone = useMediaQuery({ query: '(min-width: 300px)'})
    
  // Framer Motion Variants
  const bcfContainerVariants = {
    offscreen: (i) => {
      var holderBool;

      switch(i[1]) {
        case "Build":
          holderBool = buildCardState;
          break;
        case "Connect":
          holderBool = connectCardState;
          break;
        case "Fund":
          holderBool = fundCardState;
          break;
        }

        if (holderBool) {
          return {
            translateX: ["0px", "0px"],
            translateY: ["0px", "0px"],
            rotateY: ["0deg", "0deg"],
            transition: {type: "ease", duration: 0.33, times: [0, 1], delay: i[0]}
          }
        } else {
          return {
            translateX: "5px", translateY: "5px", rotateY: "180deg"
          }
        }
    },
    onscreen: (i) => {
      switch(i[1]) {
        case "Build":
          setBuildCardState(true)
          break;
        case "Connect":
          setConnectCardState(true)
          break;
        case "Fund":
          setFundCardState(true)
          break;
      }

      return {
        translateX: ["0px", "0px"],
        translateY: ["0px", "0px"],
        rotateY: ["0deg", "0deg"],
        transition: {type: "ease", duration: 0.33, times: [0, 1], delay: i[0]}
      }
    }
  }
  const bcfFrontCardVariants = {
    offscreen: (i) => {
      var holderBool;

      switch(i[1]) {
        case "Build":
          holderBool = buildCardState;
          break;
        case "Connect":
          holderBool = connectCardState;
          break;
        case "Fund":
          holderBool = fundCardState;
          break;
      }

      if (holderBool) {
        return {
          translateX: "0px",
          translateY: "0px",
          boxShadow: "5px 5px black",
        }
      } else {
        return { 
          translateX: "5px", translateY: "5px", boxShadow: "0px 0px black"
        }
      }
    },
    onscreen: (i) => {
      var holderBool;

      switch(i[1]) {
        case "Build":
          holderBool = buildCardState
          setBuildCardState(true)
          break;
        case "Connect":
          holderBool = connectCardState
          setConnectCardState(true)
          break;
        case "Fund":
          holderBool = fundCardState
          setFundCardState(true)
          break;
      }

      if (holderBool) {
        return {
          translateX: "0px",
          translateY: "0px",
          boxShadow: "5px 5px black",
        }
      } else {
        return {
          translateX: ["5px", "0px"],
          translateY: ["5px", "0px"],
          boxShadow: ["0px 0px black", "5px 5px black"],
          transition: {type: "ease", duration: 0.33, times: [0, 1], delay: i[0]}
        }
      }
    }
  }
  const titleVariants = {
    offscreen: {
      x: -800
    },
    onscreen: {
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    }
  };

  // Framer Helpers
  let counter = 0;
  let animationInView = 0.5;
  function increment() {
    counter += 0.5;
    return counter;
  }

  // State Hooks
  const [buildCardState, setBuildCardState] = useState(false)
  const [connectCardState, setConnectCardState] = useState(false)
  const [fundCardState, setFundCardState] = useState(false)

  // Effect Hooks
  useEffect(() => {
    $(".bcf-flip-card-container").each(function (i, obj) {
      var cardTurn = true;
      var timeoutHolder = null;
      $(this).on('click', function(evt) {
        evt.stopImmediatePropagation();
        if (cardTurn) {
          $(this).find(".bcf-flip-card-positioner").toggleClass('is-hovered-container');
          $(this).find(".bcf-flip-card-front").toggleClass('is-hovered-card-front');
          cardTurn = false;
        } else {
          $(this).find(".bcf-flip-card-positioner").toggleClass('is-hovered-container was-hovered-container');
          $(this).find(".bcf-flip-card-front").toggleClass('is-hovered-card-front was-hovered-card-front');
        }
        clearTimeout(timeoutHolder)
        timeoutHolder = setTimeout(() => {
          if ($(this).find(".bcf-flip-card-positioner").hasClass("is-hovered-container") && $(this).find(".bcf-flip-card-front").hasClass("is-hovered-card-front")) {
            $(this).find(".bcf-flip-card-positioner").toggleClass('is-hovered-container was-hovered-container');
            $(this).find(".bcf-flip-card-front").toggleClass('is-hovered-card-front was-hovered-card-front');
          }
        }, 7500)
      });
    });
  }, [isLargerThanCardCenterSplit, isLargerThanSplit]);

  // Card Specific Information
  const cardInformation = [
    {
      cardTitle: "BUILD",
      cardFrontText: "Sharpen your value proposition, develop your product, refine your market strategy, and showcase your brand.",
      cardBackItems: [
        "15+ workshops led by top firms",
        "Extensive business development support from the team and mentors",
        "AWS Cloud credits"
      ]
    },
    {
      cardTitle: "CONNECT",
      cardFrontText: "Build relationships with leaders in emerging technologies at every level – engineers, product experts, marketers, etc.",
      cardBackItems: [
        "30+ mentors and advisors to assist your business",
        "Connections with legal advisors from top firms",
        "Networking opportunities with previous cohorts and the Wharton community"
      ]
    },
    {
      cardTitle: "FUND",
      cardFrontText: "Present to a suite of top investors and corporate partners at our in-person demo day.",
      cardBackItems: [
        "Preparation for Cypher demo day, with firms such as a16z, Bain Capital, Bessemer, and Draper Ventures."
      ]
    }
  ];
  const cardComponents = cardInformation.map((cardInfo) => {
    return (
      [cardInfo['cardTitle'],
      <motion.div className="bcf-flip-card-container" initial="offscreen" whileInView="onscreen" viewport={{once:true, amount:animationInView}}>
        <motion.div className="bcf-flip-card-positioner" variants={bcfContainerVariants} custom={[increment(), "Build"]}>
          <motion.div className="bcf-flip-card-front card" variants={bcfFrontCardVariants} custom={[counter, "Build"]}>
            <Card.Body>
              <Card.Title>{cardInfo['cardTitle']}</Card.Title>
              <Card.Text>{cardInfo['cardFrontText']}</Card.Text>
            </Card.Body>
          </motion.div>
          <Card className="bcf-flip-card-back">
            <Card.Body>
            <Card.Title>{cardInfo['cardTitle']}</Card.Title>
              <ListGroup variant='flush'>
                {cardInfo['cardBackItems'].map((item) => {
                  return (
                    <ListGroup.Item>{item}</ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </motion.div>
      </motion.div>]
    );
  })

  // Render Method
  return (
    <div className="information">
      <motion.div className="information-text" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div style={{border: 0}} variants={titleVariants}>
          <div className="section-subtitle-text">We focus our efforts on supporting you.</div>
          <div className="section-subtitle-text-large">Leveraging our vast resources to scale your business.</div>
        </motion.div>
      </motion.div>
      {isLargerThanSplit ? (
        <div className="bcf-cards-container">
          {cardComponents.map(([_, cardComponent]) => {
            return cardComponent;
          })}
        </div>
      ) : (
        <div className="bcf-cards-container">
          <Swiper effect={"coverflow"} grabCursor={true} centeredSlides={true} slidesPerView={"auto"} spaceBetween={isLargerThanSmallPhone ? 30 : 20}
          coverflowEffect={{rotate: 0, stretch: 0, scale: 0.9, modifier: 1, slideShadows: false}} pagination={true} initialSlide={isLargerThanCardCenterSplit ? 1 : 0}>
            {cardComponents.map(([cardTitle, cardComponent]) => {
              return (
                <SwiperSlide id={`swiper-${cardTitle}`}>
                  {cardComponent}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      <motion.div className="information-text" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div style={{border: 0}} variants={titleVariants}>
          <p className="section-subtitle-text-centered">
          <a href='https://drive.google.com/file/d/160JKVYMlxFN8Two7wIsBynrrwuJWbRQp/view?usp=sharing' target="_blank" rel="noopener noreferrer">Disclaimer</a><br/>
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Information;