import './App.css';

import "bootstrap/dist/css/bootstrap.min.css"
import './components/Component.css';
import AnimatedRoutes from "./components/AnimatedRoutes"

import "@fontsource/metropolis/100.css"
import "@fontsource/metropolis/300.css"
import "@fontsource/metropolis/400.css"
import "@fontsource/metropolis/500.css"
import "@fontsource/metropolis/600.css"
import "@fontsource/metropolis/700.css"
import "@fontsource/metropolis/800.css"
import "@fontsource/metropolis/900.css"

//Firebase imports

function App() {
  return (
    <div className="App">
      <AnimatedRoutes />
    </div>
    
  );
}




export default App;
