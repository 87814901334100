import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

import Image from 'react-bootstrap/Image'
import Stack from 'react-bootstrap/Stack';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { MdSearchOff} from 'react-icons/md'

import Navibar from '../components/Navibar';
import Footer from '../components/Footer';
import Member from '../components/Member'
import PageSlider from "../components/Page-Slider"

import People from '../assets/images/people.svg'
import Search from '../assets/images/search.svg'
import BackgroundSlide1 from '../assets/images/quote-cards/backgrounds/slide_0.png'
import BackgroundSlide2 from '../assets/images/quote-cards/backgrounds/slide_1.png'
import BackgroundSlide3 from '../assets/images/quote-cards/backgrounds/slide_2.png'

import CypherLoading from '../components/CypherLoading'
import $ from 'jquery';
import CypherTeam from '../assets/cypher-team.json'
import CypherAlumni from '../assets/cypher-alumni.json'
import Advisors from '../assets/advisors.json'
import Mentors from '../assets/mentors.json'
import Quotes from '../assets/team-quotes.json'


function TeamPage() {
	// Media Queries
	const isLargerThanSplit = useMediaQuery({ query: '(min-width: 1200px)'});

	// Member Card Arrays
	const CypherTeamMembers = [];
	const CypherAlumniMembers = [];
    const AdvisorMembers = [];
	const MentorMembers = [];
	const MentorMembersNoBadges = [];
	const AllMembers = [];

	// Ref Hooks
    const videoRef = useRef(null)

	// State Hooks
	const [checkboxArrayState, setCheckboxArrayState] = useState([])
	const [radioState, setRadioState] = useState("cypher-team")
	const [searchState, setSearchState] = useState("")
	const [liveArray, setLiveArray] = useState([CypherTeamMembers])
	const [loadingState, setLoadingState] = useState(false)

	// Name Storage Arrays
	var nameArray = [];
	const allNames = [];

	// Expertise Checkbox Arrays
	var expertiseNameLists = [];
	const expertiseCheckboxes = [];
	const expertiseAreas = ["AI", "Financial Inclusion", "Robotics", "Quantum", "Security", "TradFi", "NFTs", "Regulation", "Tokenomics", "Infra", "Community / DAO", "Gaming", "Financial Services", "Partnerships / Strategy", "Metaverse"];
	const expertiseColorMap = new Map([
		["AI", '#fff56d'],
		["Financial Inclusion", '#D291BC'],
		["Robotics", '#6dc5ff'],
		["Quantum", '#957DAD'],
		["Security", '#74D3F1'],
		["TradFi", '#F174B0'],
		["NFTs", '#F1EC74'],
		["Regulation", "#74F188"],
		["Tokenomics", "#7E74F1"],
		["Infra", "#F1A874"],
		["Community / DAO", "#74F1E2"],
		["Gaming", "#F17474"],
		["Financial Services", "#F174DD"],
		["Partnerships / Strategy", "#F2C879"],
		["Metaverse", "#A0B392"]
	]);
		
	// Effect Hooks
	useEffect(() => {
		updateTeamPageState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [radioState, searchState, checkboxArrayState]);
	useEffect(() => {
		// Preloading Profile Images
		const cypherImages = importAll(require.context('../assets/images/cypher-team/gradient', false, /\.(png|jpe?g|svg)$/));
		const mentorImages = importAll(require.context('../assets/images/mentors/gradient', false, /\.(png|jpe?g|svg)$/));
		const advisorImages = importAll(require.context('../assets/images/advisors/gradient', false, /\.(png|jpe?g|svg)$/));
		const imageList = [...cypherImages, ...mentorImages, ...advisorImages]
		
		imageList.forEach((image) => {
			const newImage = document.createElement("img");
            newImage.src = image;
            window[image] = newImage;
		})
		
		// Searchbar bgColor Updates
		let searchBox = document.querySelector(".team-search-container");
		searchBox.addEventListener("mousedown", function() {
			searchBox.style.backgroundColor = "white";
		})
		document.addEventListener("mousedown", function(event) {
			if (event.target.closest(".team-search-container")) return;
			searchBox.style.backgroundColor = "#ededed";
		})
	})
	useLayoutEffect(() => {
		document.body.classList.add("background-static")
		setLoadingState(true)
		setTimeout(() => {
			setLoadingState(false)
			document.body.classList.remove("background-static")
			$('body,html').animate({ scrollTop: 0, }, 0);
		}, 5000)
		
	}, [])

	// Functions
	function importAll(r) {
		return r.keys().map(r);
	}
	function loadNameDataStructure(arr) {
		nameArray = new Array(26);

		for (var i = 0; i < nameArray.length; i++) {
			nameArray[i] = [];
		}

		for (i = 0; i < arr.length; i++ ){
			var nameSplit = arr[i].split(" ");
			var fName = nameSplit[0].toLowerCase();
			var fNameCharacter = fName.charCodeAt(0) - 97;
			var lName = nameSplit[1].toLowerCase();
			var lNameCharacter = lName.charCodeAt(0) - 97;
			var fullName = arr[i].toLowerCase();
			var fullNameCharacter = fullName.charCodeAt(0) - 97;

			nameArray[fNameCharacter].push([fName, arr[i]]);
			nameArray[lNameCharacter].push([lName, arr[i]]);
			nameArray[fullNameCharacter].push([fullName, arr[i]]);
		}
	}
	function loadExpertiseArray() {
		expertiseNameLists = new Array(expertiseAreas.length);
		for (var i = 0; i < expertiseNameLists.length; i++) {
			expertiseNameLists[i] = [];
		}
	}
	function resetSearchValue() {
		const input = document.getElementById("team-search");
		input.value = "";
		setSearchState("");
	}
	function returnPersonDescription() {
		switch (radioState) {
			case "cypher-team" :
				return ""
			case "cypher-alumni" :
				return ""
			case "advisors" :
				return ""
			case "mentors" :
				return ""
			default :
				return ""
		}
	}
	function getCheckboxValues() {
		var checkboxes = document.getElementsByClassName("expertise-pill-checkbox");
		var tempCheckboxArray = [];
		for (var i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				tempCheckboxArray.push(checkboxes[i].value);
			}
		}
		return tempCheckboxArray;
	}
	function updateTeamPageState() {
		var str = searchState.toLowerCase().replace(/\s+/g, ' ').trim();
		var checkboxFilteredNames = [];
		var newLiveArray = [];

		for (var topic of checkboxArrayState) {
			checkboxFilteredNames.push(...expertiseNameLists[expertiseAreas.indexOf(topic)]);
		}
		checkboxFilteredNames = [...new Set(checkboxFilteredNames)];
		
		if (str === "") {
			switch (radioState) {
				case "cypher-team" :
					setLiveArray(CypherTeamMembers);
					break;
				case "cypher-alumni" :
					setLiveArray(CypherAlumniMembers);
					break;
				case "advisors" :
					setLiveArray(AdvisorMembers);
					break;
				case "mentors" :
					// if (checkboxArrayState.length === 0) {
					// 	setLiveArray(MentorMembers);
					// } else {
					// 	newLiveArray = MentorMembers.filter(element => checkboxFilteredNames.includes(element.key));
					// 	setLiveArray(newLiveArray);
					// }
					setLiveArray(MentorMembers);
					break;
				case "everyone" :
					setLiveArray(AllMembers);
					break;
				default :
					break;
			}
		} else {
			var tempFilteredNameArray = [];
			var firstCharacter = str.charCodeAt(0) - 97;
			var strLength = str.length;
			
			for (var tup of nameArray[firstCharacter]) {
				if (strLength <= tup[0].length) {
					if (str === tup[0].slice(0, strLength)) {
						tempFilteredNameArray.push(tup[1]);
					}
				}
			}

			switch (radioState) {
				case "cypher-team" :
					newLiveArray = CypherTeamMembers.filter(element => tempFilteredNameArray.includes(element.key));
					break;
				case "cypher-alumni" :
					newLiveArray = CypherAlumniMembers.filter(element => tempFilteredNameArray.includes(element.key));
					break;
				case "advisors" :
					newLiveArray = AdvisorMembers.filter(element => tempFilteredNameArray.includes(element.key));
					break;
				case "mentors" :
					// if (checkboxArrayState.length !== 0) {
					// 	tempFilteredNameArray = checkboxFilteredNames.filter(value => tempFilteredNameArray.includes(value));
					// }
					newLiveArray = MentorMembers.filter(element => tempFilteredNameArray.includes(element.key));
					break;
				case "everyone" :
					newLiveArray = AllMembers.filter(element => tempFilteredNameArray.includes(element.key));
					break;
				default :
					break;
			}

			setLiveArray(newLiveArray);
			
		}
	}
	function returnSearchOutput() {
		if (searchState && !liveArray.length) {
			return (
				<div className="no-search-output">
					<hr className="top-bar"/>
					<MdSearchOff/>
					<div className="no-search-output-text">No search results found.</div>
					<hr className="bottom-bar"/>
				</div>
			)
		} else {
			return (
				<div className="search-output">
					<div className="person-description">{returnPersonDescription()}</div>
					<div className="team-array">{liveArray}</div>
				</div>
			)
		}
	}

	// Executed Code
	loadExpertiseArray();
	for (var key in CypherTeam) {
        CypherTeamMembers.push(<Member name={key} key={key} type="Cypher" data={CypherTeam[key]} badgeColors={[]} showBadges={false} secondaryPage={true}></Member>)
		allNames.push(key);
    }
	for (var key in CypherAlumni) {
        CypherAlumniMembers.push(<Member name={key} key={key} type="Alumni" data={CypherAlumni[key]} badgeColors={[]} showBadges={false} secondaryPage={true}></Member>)
		allNames.push(key);
    }
	for (key in Advisors) {
        AdvisorMembers.push(<Member name={key} key={key} type="Advisor" data={Advisors[key]} badgeColors={[]} showBadges={false} secondaryPage={true}></Member>)
		allNames.push(key);
    }
	for (key in Mentors) {
		var tempColorArray = [];
		// for (var topic of Mentors[key].Expertise) {
		// 	tempColorArray.push(expertiseColorMap.get(topic));
		// 	expertiseNameLists[expertiseAreas.indexOf(topic)].push(key);
		// }

        MentorMembers.push(<Member name={key} key={key} type="Mentor" data={Mentors[key]} badgeColors={tempColorArray} showBadges={false} secondaryPage={true}></Member>)
		// MentorMembersNoBadges.push(<Member name={key} key={key} type="Mentor" data={Mentors[key]} badgeColors={tempColorArray} showBadges={false} secondaryPage={true}></Member>)
		allNames.push(key);
    }
	// for (var topic of expertiseAreas) {
	// 	expertiseCheckboxes.push(<span className="expertise-pill" key={topic}>
	// 		<input className="expertise-pill-checkbox" type="checkbox" id={topic} value={topic} onChange={(evt) => {setCheckboxArrayState(getCheckboxValues)}}/>
	// 		<label className="expertise-pill-text" style={{backgroundColor: expertiseColorMap.get(topic)}} htmlFor={topic}>{topic}</label>
	// 	</span>)
	// }
	loadNameDataStructure(allNames);
	AllMembers.push(...CypherTeamMembers, ...CypherAlumniMembers, ...AdvisorMembers, ...MentorMembers);
	AllMembers.sort((a, b) => {
		const aLast = a.key.split(" ")[1]
		const bLast = b.key.split(" ")[1]
		return aLast.localeCompare(bLast);
	})
	
	// Render Method
  	return (
		<div className="team-page">
			<AnimatePresence style={{width: '100vw'}}>
                {loadingState && <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {
					duration: 0.5, type: "ease"}}}>
						<div className="cypher-loading">
                            <CypherLoading/>
                        </div>
                        {/* <video className="loading-video" src={CypherLoadingVideo} ref={videoRef} poster={CypherLoadingPoster} preload={'auto'} loop autoPlay playsInline muted type="video/mp4"></video> */}
                    </motion.div>
                }
			</AnimatePresence>
			{/* <AnimatePresence>
				{loadingState && <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {
					duration: 0.5, type: "ease"}}}></motion.div>}
			</AnimatePresence> */}
			<PageSlider />
			<Navibar secondaryPage={true}/>
			<div className="secondary-page-wrapper">
				<div className='secondary-page-header'>
					{/* <Image src={People}/> */}
					<h1 className='section-title-text'>Our Team.</h1>
				</div>
				<QuoteCarousel/>
				<div className="interact-panel">
					{isLargerThanSplit &&
						<div className="interact-flex-group">
							<div className="team-radio-group" onChange={(evt) => {resetSearchValue(); setRadioState(evt.target.value)}}>
								<label className="team-radio">
									<input type="radio" value="cypher-team" name="radio" className="team-radio-input" defaultChecked/>
									<div className="team-radio-button"></div>
									Cypher Team
								</label>
								<label className="team-radio">
									<input type="radio" value="advisors" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Advisors
								</label>
								<label className="team-radio">
									<input type="radio" value="mentors" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Mentors
								</label>
								<label className="team-radio">
									<input type="radio" value="cypher-alumni" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Cypher Alumni
								</label>
								<label className="team-radio">
									<input type="radio" value="everyone" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Everyone
								</label>
							</div>
							<div className="team-search-container">
								<Image src={Search}/>					
								<input id="team-search" type="test" className="team-search-bar" placeholder="Search by name" onChange={(evt) => {setSearchState(evt.target.value)}}/>
							</div>
						</div>
					}
					{!isLargerThanSplit &&
						<div className="interact-flex-group">
							<div className="team-search-container">
								<Image src={Search}/>					
								<input id="team-search" type="test" className="team-search-bar" placeholder="Search by name" onChange={(evt) => {setSearchState(evt.target.value)}}/>
							</div>
							<div className="team-radio-group" onChange={(evt) => {resetSearchValue(); setRadioState(evt.target.value)}}>
								<label className="team-radio">
									<input type="radio" value="cypher-team" name="radio" className="team-radio-input" defaultChecked/>
									<div className="team-radio-button"></div>
									Cypher Team
								</label>
								<label className="team-radio">
									<input type="radio" value="advisors" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Advisors
								</label>
								<label className="team-radio">
									<input type="radio" value="mentors" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Mentors
								</label>
								<label className="team-radio">
									<input type="radio" value="cypher-alumni" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Cypher Alumni
								</label>
								<label className="team-radio">
									<input type="radio" value="everyone" name="radio" className="team-radio-input"/>
									<div className="team-radio-button"></div>
									Everyone
								</label>
							</div>
						</div>
					}
					{/* {radioState === "mentors" && <hr/>}
					{radioState === "mentors" && <Stack direction="horizontal">{expertiseCheckboxes}</Stack>} */}
				</div>
				{returnSearchOutput()}
			</div>
			<Footer/>
		</div>
  	);
}

// Carousel Creation Method
function QuoteCarousel() {
	// Arrays
	const QuoteCards = [];
	const QuoteBackgrounds = [BackgroundSlide1, BackgroundSlide2, BackgroundSlide3]

	// Media Query
	const isLargerThanQuoteSplit = useMediaQuery({ query: '(min-width: 1000px)'});

	// Quote Card Parameters
	const carouselLength = 3;
	const intervalTime = 10000;

	// Functions
	function createQuoteCards() {
		var counter = 0;
		for (var key in Quotes) {
			if (carouselLength > counter) {
				QuoteCards.push(
					<Carousel.Item interval={intervalTime} key={key + "-quote"}>
						<Card>
							{isLargerThanQuoteSplit &&
								<Card.Body style={{backgroundImage: "url(" + QuoteBackgrounds[counter] + ")"}}>
									<Card.Img src={require(`../assets/images/quote-cards/headshots/${Quotes[key].Image}.png`)}></Card.Img>
									<div className="carousel-text-container">
										<Card.Text>{Quotes[key].Quote}</Card.Text>
										<Card.Title>{key}</Card.Title>
										<Card.Subtitle>{Quotes[key].Position}</Card.Subtitle>
									</div>
								</Card.Body>
							}
							{!isLargerThanQuoteSplit &&
								<Card.Body style={{backgroundImage: "url(" + QuoteBackgrounds[counter] + ")"}}>
									<Card.Text>{Quotes[key].Quote}</Card.Text>
									<div className="carousel-bottom-container">
										<Card.Img src={require(`../assets/images/quote-cards/headshots/${Quotes[key].Image}.png`)}></Card.Img>
										<div className="carousel-text-container">
											<Card.Title>{key}</Card.Title>
											<Card.Subtitle>{Quotes[key].Position}</Card.Subtitle>
										</div>
									</div>
								</Card.Body>
							}
						</Card>
					</Carousel.Item>
				)
			}
			counter++;
		}
	}

	// Executed Code
	createQuoteCards();
	
	// Render Method
	return (
		<Carousel touch={true}>
			{QuoteCards}
		</Carousel>
	);
}

export default TeamPage;