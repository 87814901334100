import React from 'react';
import Card from 'react-bootstrap/Card';
import { FaTwitter, FaLinkedinIn} from 'react-icons/fa'
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';

function Member(props) {
  const name = props.name;
  const type = props.type;
  const data = props.data;
  const isTeamPage = props.secondaryPage;
  const position = data.Position;
  const isTwitter = data.Twitter != "";
  const isLinkedIn = data.LinkedIn != "";
  const expertise = data.Expertise;
  const colors = props.badgeColors;
  const showBadges = props.showBadges;
  var tags = [];

  if (isTeamPage && showBadges && type === "Mentor") {
    tags = expertise.map((tag, index) =>
      <Badge pill key={tag} style={{backgroundColor: colors[index], color: 'black'}} bg="">
        {tag}
      </Badge>
    );
  };

  return (
    <div className="member-card-container" id={data.Image}>
      <Card>
        {isTeamPage ? (
          <Card.Body>
            <Card.Img src={type === 'Cypher' ? require(`../assets/images/cypher-team/gradientblue/${data.Image}_gradient.png`) : type === 'Alumni' ? require(`../assets/images/cypher-team/gradientblue/${data.Image}_gradient.png`) :type === 'Advisor' ? require(`../assets/images/advisors/gradientblue/${data.Image}_gradient.png`) : require(`../assets/images/mentors/gradientblue/${data.Image}_gradient.png`)}/>
            <div className="member-card-information">
              <div className="member-text-container">
                <Card.Title>{name}</Card.Title>
                <Card.Subtitle>{position}</Card.Subtitle>
              </div>
              <div className="card-icons">
                {isLinkedIn && <a href={data.LinkedIn} target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn size={23} color={isTeamPage ? "white" : type === "Cypher" ? "black" : "white"}/>
                </a>}
                {isTwitter && <a href={data.Twitter} target="_blank" rel="noopener noreferrer">
                  <FaTwitter size={23} color={isTeamPage ? "white" : type === "Cypher" ? "black" : "white"}/>
                </a>}
              </div>
            </div>
            {showBadges && <Stack direction="horizontal">{tags}</Stack>}
          </Card.Body>
        ) : (
          <Card.Body>
            <Card.Img src={type === 'Cypher' ? require(`../assets/images/cypher-team/regular/${data.Image}.png`) : type === 'Advisor' ? require(`../assets/images/advisors/regular/${data.Image}.png`) : require(`../assets/images/mentors/regular/${data.Image}.png`)}/>
            <Card.Title>{name}</Card.Title>
            <Card.Subtitle>{position.toUpperCase()}</Card.Subtitle>
            <div className="card-icons">
              {isLinkedIn && <a href={data.LinkedIn} target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn size={25} color={isTeamPage ? "white" : type === "Cypher" ? "black" : "white"}/>
              </a>}
              {isTwitter && <a href={data.Twitter} target="_blank" rel="noopener noreferrer">
                <FaTwitter size={25} color={isTeamPage ? "white" : type === "Cypher" ? "black" : "white"}/>
              </a>}
            </div>
          </Card.Body>
        )}
      </Card>
      <div>
        <div className="scroll-cushion"></div>
      </div>
    </div>
  );
}

export default Member;